.footerContainer {
  display: flex;
  flex-direction: column;
  padding: 64px 120px 120px;
  background-color: #ffffff;
  min-height: 356px;
}

@media (max-width: 576px) {
  .footerContainer {
    padding: 64px;
  }
}

.footer {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  gap: 40px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: wrap;
}

@media (min-width: 1400px) {
  .footer {
    max-width: 1320px;
  }
}

.footerColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header {
  color: rgba(var(--bs-dark-rgb), 1);
  font-weight: 500;
  margin-bottom: 1rem;
}

.a {
  color: #485056;
  text-decoration: none;
}
