@font-face {
  font-family: "Utopia Std Display";
  src: url("../public/fonts/UtopiaStd-Display.otf") format("opentype")
}

@font-face {
  font-family: "Utopia Std";
  src: url("../public/fonts/UtopiaStd.otf") format("opentype")
}

@font-face {
  font-family: "Utopia Std Bold";
  src: url("../public/fonts/UtopiaStd-Bold.otf") format("opentype")
}

@font-face {
  font-family: "ABCWhyte";
  src: url("../public/fonts/ABCWhyte-Regular.otf") format("opentype")
}

@font-face {
  font-family: "ABCWhyte Italic";
  src: url("../public/fonts/ABCWhyte-RegularItalic.otf") format("opentype")
}

@font-face {
  font-family: "ABCWhyte Black";
  src: url("../public/fonts/ABCWhyte-Black.otf") format("opentype")
}

@font-face {
  font-family: "ABCWhyte Black Italic";
  src: url("../public/fonts/ABCWhyte-BlackItalic.otf") format("opentype")
}

@font-face {
  font-family: "ABCWhyte Bold";
  src: url("../public/fonts/ABCWhyte-Bold.otf") format("opentype")
}

@font-face {
  font-family: "ABCWhyte Bold Italic";
  src: url("../public/fonts/ABCWhyte-BoldItalic.otf") format("opentype")
}

@font-face {
  font-family: "ABCWhyte Book";
  src: url("../public/fonts/ABCWhyte-Book.otf") format("opentype")
}

@font-face {
  font-family: "ABCWhyte Book Italic";
  src: url("../public/fonts/ABCWhyte-BookItalic.otf") format("opentype")
}

@font-face {
  font-family: "ABCWhyte Extralight";
  src: url("../public/fonts/ABCWhyte-Extralight.otf") format("opentype")
}

@font-face {
  font-family: "ABCWhyte Extralight Italic";
  src: url("../public/fonts/ABCWhyte-ExtralightItalic.otf") format("opentype")
}

@font-face {
  font-family: "ABCWhyte Heavy";
  src: url("../public/fonts/ABCWhyte-Heavy.otf") format("opentype")
}

@font-face {
  font-family: "ABCWhyte Heavy Italic";
  src: url("../public/fonts/ABCWhyte-HeavyItalic.otf") format("opentype")
}

@font-face {
  font-family: "ABCWhyte Light";
  src: url("../public/fonts/ABCWhyte-Light.otf") format("opentype")
}

@font-face {
  font-family: "ABCWhyte Light Italic";
  src: url("../public/fonts/ABCWhyte-LightItalic.otf") format("opentype")
}

@font-face {
  font-family: "ABCWhyte Medium";
  src: url("../public/fonts/ABCWhyte-Medium.otf") format("opentype")
}

@font-face {
  font-family: "ABCWhyte Medium Italic";
  src: url("../public/fonts/ABCWhyte-MediumItalic.otf") format("opentype")
}

@font-face {
  font-family: "ABCWhyte Super";
  src: url("../public/fonts/ABCWhyte-Super.otf") format("opentype")
}

@font-face {
  font-family: "ABCWhyte Super Italic";
  src: url("../public/fonts/ABCWhyte-SuperItalic.otf") format("opentype")
}

@font-face {
  font-family: "ABCWhyte Thin";
  src: url("../public/fonts/ABCWhyte-Thin.otf") format("opentype")
}

@font-face {
  font-family: "ABCWhyte Thin Italic";
  src: url("../public/fonts/ABCWhyte-ThinItalic.otf") format("opentype")
}