.articleLink {
  color: inherit;
  text-decoration: none;
}

.cardImg {
  height: 160px;
  object-fit: cover;
  border-radius: 0px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.articleTitle {
  font-size: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-bottom: 1rem;
  color: #495057;
  margin-bottom: 0px;
  padding: 4px;
}

.cursorCreateBookmarkedArticle {
  display: flex;
  cursor: pointer;
}

.shareButton {
  text-decoration: none;
  display: flex;
  cursor: pointer;
}

.shareContainer {
  display: flex;
  height: 200px;

  img {
    border-radius: 8px;
    object-fit: cover;
    aspect-ratio: 1;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    margin-inline-start: 20px;
    z-index: 1;
    text-align: center;

    width: 280px;
    margin: 42px auto;
    h2 {
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      color: #ffffff;
    }
    span {
      font-weight: 350;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: -0.03em;
      color: #c1c2c4;
      span {
        color: #ffffff;
        font-weight: 600;
      }
    }
  }
}

.urlBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 12px 16px 16px;
  gap: 8px;
  width: 450px;
  height: 83px;
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  margin: 32px auto;

  div {
    width: 350px;
    font-weight: 350;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.03em;
    color: #282b32;
  }

  button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 16px 8px 14px;
    gap: 10px;
    width: 64px;
    height: 30px;
    background: #d9ff40;
    border: 1px solid #1f211c;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #282b32;
  }
}

.imgThumbnail {
  position: absolute;
  left: -1px;
  top: -1px;
  bottom: 0px;
  width: 100.5%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  object-fit: cover;
  height: 220px;
}

.opacityBox {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(8px);
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 350;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #626467;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #f0f0f0;
}

.separator:not(:empty)::before {
  margin-right: 0.55em;
}

.separator:not(:empty)::after {
  margin-left: 0.55em;
}

.btnShareSocial {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 12px;
  gap: 8px;
  width: 76.8px;
  height: 32px;
  border-radius: 4px;
  color: #fff;
}

.btnTwitter {
  background: #1da1f2 !important;
}

.btnEmail {
  background: #ffffff !important;
  border: 1px solid #d9d9d9 !important;
}

.btnFacebook {
  background: #1877f2 !important;
}

.btnReddit {
  background: #ff4500 !important;
}

.btnlinkedin {
  background: #0c64c5 !important;
}

.btnslack {
  background: #4a154b !important;
}

.btndiscord {
  background: #5865f2 !important;
}

.btnteams {
  background: white !important;
  border: 1px solid #d9d9d9 !important;
}

.footerBtns {
  border: 0;
  justify-content: center;
  margin: 0 50px 20px;
}

.btnClose {
  box-sizing: border-box;
  position: absolute;
  width: 32px;
  height: 32px;
  right: 16px;
  top: 16px;
  background: #ffffff;
  border: 1px solid #141414;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px;
}
