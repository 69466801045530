.main {
  padding: 10px;
}
.contanierTitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.title {
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  color: #1e1e1e;
}

.btnClose {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 100px;
}

.containerAction {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.btn {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  //width: 77px;
  height: 45px;
  border-radius: 8px !important;
  font-weight: 400;
  font-size: 14px;
}

.cancelBtn {
  background: #ffffff;
  border: 1px solid #e5e5e5 !important;
  color: #353535;
}

.btnSubmit {
  background: #000000;
  border: 1px solid #676767;
  color: #dddddd;
}
.spinnerBox {
  text-align: center;
  margin-top: 12px;
}
