$primary: #0b0b0b;
:root {
  --toastify-text-color-success: #fff;
  --toastify-color-success: #16b364 !important;
  --toastify-toast-width: fit-content;
  --bs-info-rgb: 0, 0, 0 !important;
}

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
);

$container-max-widths: (
  sm: 570px,
  md: 760px,
  lg: 990px,
  xl: 1180px,
  xxl: 1380px,
  xxxl: 1580px,
);

@import '~bootstrap/scss/bootstrap';

body {
  background-color: #fafafa;
}

.btn-primary {
  border-radius: 2px;
}

.modal-90w {
  width: 90%;
  max-width: none !important;
}

.modal-90h {
  height: 90%;
  max-height: none !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-color: transparent transparent black !important;
  background-color: transparent;
}

.social-media button {
  width: 100%;
}

.modal-content {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  background: #fafafa;
}

.pagination {
  justify-content: center;
}

.progress {
  height: 48px;
  background-color: #e9e9e9;
  border-radius: 8px;
}

@import "custom_fonts";