.zette_profile_btn {
  cursor: pointer;
  height: 32px;
  width: 32px;
  font-weight: 700;
  font-size: 1.4rem;
  margin-inline-start: 1rem;
  background: #141414;
  border-radius: 8px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.zette_profile_btn_big {
  width: 48px;
  height: 48px;
}

.profile_first_name_letter {
  padding-top: 0.1rem;
  padding-left: 0.1rem;
}

.navbar {
  background-color: #fafafa;
  background: url('/zette_branded_background.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 296px;
  align-items: flex-start !important;
  box-shadow: none !important;
  flex-direction: column;
  justify-content: flex-start;
}

.navbarShort {
  height: 120px;
}

.creditsBanner {
  position: absolute;
  top: 230px;
  left: -205px;
  padding: 0.75rem;
  border-radius: 8px;
  border: 1px solid #e9e9e9;
  background-color: white !important;
  z-index: 1000;
  display: inline-block;
}

a.nostyle:link {
  text-decoration: inherit;
  color: inherit;
}

a.nostyle:visited {
  text-decoration: inherit;
  color: inherit;
}

.title {
  font-family: Utopia Std;
  font-size: 32px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
}

.subtitle {
  font-size: 16px;
  font-weight: 200;
  line-height: 26px;
  letter-spacing: -0.03em;
  text-align: left;
}

.headerTop {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: 3rem;
}

.textHeader {
  font-weight: 350;
  font-size: 14px;
  line-height: 160%;
}

.textGray {
  color: #999999;
}

.textBlack {
  margin-left: 5px;
  cursor: pointer;
  color: #000000;
}

.avatar {
  object-fit: cover;
  border-radius: 8px;
}

.dropDownMenu {
  min-width: 288px !important;
  color: #282B32 !important;
  border: 1px solid #F0F0F0 !important;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06) !important;
  border-radius: 8px !important;

  a {
    padding: 0.65rem 1.5rem;
    color: #282B32;
    font-size: 14px;
  }

  hr {
    border-top: 1px solid #C0C0C0;
  }
}

.itemMenuText {
  color: #626467 !important;
  font-weight: 350 !important;
  display: flex !important;
  flex-direction: column;
  gap: 8px;
  margin-left: 10px;
  font-size: 14px;
}