.main {
  padding: 40px;
}

.modalCustom {
  width: 620px !important;
}

.contanierTitle {
  display: flex;
  justify-content: space-between;
}

.title {
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  color: #1e1e1e;
}

.btnClose {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 100px;
}

.containerFeedbackBtn {
  display: flex;
}

.textFeed {
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: #4e4e4e;
  margin-top: 40px;
  margin-bottom: 10px;
}

.textAreaFeed {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 20px;
  gap: 10px;
  width: 540px;
  height: 140px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  resize: none;

  &:focus {
    outline: none !important;
    border: 1px solid #4e4e4e;
  }

  &::placeholder {
    font-size: 14px;
    line-height: 160%;
    color: #888888;
  }
}

.btnEmoji {
  font-family: 'ABC Whyte Trial';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 100%;
  letter-spacing: -0.011em;
  color: #000000;
  background: #ffffff;
  border: 0;
}

.containerAction {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.btn {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  //width: 77px;
  height: 45px;
  border-radius: 8px !important;
  font-weight: 400;
  font-size: 14px;
}

.cancelBtn {
  background: #ffffff;
  border: 1px solid #e5e5e5 !important;
  color: #353535;
}

.btnSubmit {
  background: #000000;
  border: 1px solid #676767;
  color: #dddddd;
}
.unSelectEmoji {
  opacity: 0.5;
}
